import { isMobile, checkoutFormHandler } from "./helper";
import SiteHeader from "./siteHeader";
import { toggleMenu, addSticky, closeMenu } from "./navigation";
import FloatLabels from "./forms/float-labels";
import QuantityButtons from "./woocommerce/quantityButtons";

window.addEventListener("load", () => {
  new SiteHeader();
  new FloatLabels();
  new QuantityButtons();

  const formCheckout = document.querySelector("form.woocommerce-checkout");
  if (formCheckout) {
    checkoutFormHandler(formCheckout);
  }

  toggleMenu();

  if (isMobile()) {
    closeMenu();
    addSticky();
  }
});

window.onresize = () => {
  if (isMobile()) {
    closeMenu();
    addSticky();
  }
};

const thanks = document.getElementById("thanks");
const placeForThanks = document.getElementById("placeForThanks");

if (thanks) {
  placeForThanks.append(thanks);
}

const descritpionButton = document.getElementById("descritpion");
const opinionsButton = document.getElementById("opinions");
const descriptionBox = document.getElementById("descritpionBox");
const opinionsBox = document.getElementById("opinionsBox");

if (descritpionButton && opinionsButton) {
  descritpionButton.onclick = e => {
    e.preventDefault();
    opinionsBox.style.display = "none";
    descriptionBox.style.display = "block";
    descritpionButton.classList.add("activeButton");
    opinionsButton.classList.remove("activeButton");
  };

  opinionsButton.onclick = e => {
    e.preventDefault();
    descriptionBox.style.display = "none";
    opinionsBox.style.display = "block";
    opinionsButton.classList.add("activeButton");
    descritpionButton.classList.remove("activeButton");
  };
}

