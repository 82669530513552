//js
if (!global._babelPolyfill) {
  require("babel-polyfill");
};
require("./js/woocommerce/quantityButtons");
require("./js/slider");
require("./js/tabs");
require("./js/scroll");
require("./js/helper");
require("./js/navigation");
require("./js/maps");
require("./js/main");

//fonts
// require('./fonts/font/stylesheet.css');

// css
import "normalize.css";
require("./sass/style.scss");
