const button1 = document.getElementById("button1");
const button2 = document.getElementById("button2");
const tab1 = document.getElementById("tab1");
const tab2 = document.getElementById("tab2");

if(button1 && button2) {
    button1.onclick = (e) => {
        e.preventDefault();
        tab1.style.display = "initial";
        tab2.style.display = "none";
        button1.classList.add("buttonActive");
        button2.classList.remove("buttonActive");
    };
    button2.onclick = (e) => {
        e.preventDefault();
        tab2.style.display = "initial";
        tab1.style.display = "none";
        button2.classList.add("buttonActive");
        button1.classList.remove("buttonActive");
    };
}