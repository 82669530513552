export const checkoutFormHandler = container => {
  const firstName = container.querySelector("#billing_first_name_field");
  const lastName = container.querySelector("#billing_last_name_field");
  const company = container.querySelector("#billing_company_field");
  const nip = container.querySelector("#billing_nip_field");
  const individualInput = container.querySelector(
    "#type-of-customer-individual"
  );
  const businessInput = container.querySelector("#type-of-customer-business");

  individualInput.checked = true;
  company.style.display = "none";
  nip.style.display = "none";

  individualInput.addEventListener("click", e => {
    if (e.target.checked == true) {
      firstName.style.display = "block";
      lastName.style.display = "block";
      company.style.display = "none";
      nip.style.display = "none";
    }
  });

  businessInput.addEventListener("click", e => {
    if (e.target.checked == true) {
      firstName.style.display = "none";
      lastName.style.display = "none";
      company.style.display = "block";
      nip.style.display = "block";
    }
  });
};

export const getScreenWidth = () => {
  let w = window,
    d = document,
    e = d.documentElement,
    g = d.getElementsByTagName("body")[0],
    width = w.innerWidth || e.clientWidth || g.clientWidth;

  return width;
};

export const getScreenHeight = () => {
  let w = window,
    d = document,
    e = d.documentElement,
    g = d.getElementsByTagName("body")[0],
    height = w.innerHeight || e.clientHeight || g.clientHeight;

  return height;
};

export const isMobile = () => {
  if (getScreenWidth() <= 768) return true;
};

export const isPhone = () => {
  if (getScreenWidth() <= 440) return true;
};

export const getCoords = elem => {
  let box = elem.getBoundingClientRect();

  let body = document.body;
  let docEl = document.documentElement;

  let scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
  let scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft;

  let clientTop = docEl.clientTop || body.clientTop || 0;
  let clientLeft = docEl.clientLeft || body.clientLeft || 0;

  let top = box.top + scrollTop - clientTop;
  let left = box.left + scrollLeft - clientLeft;

  return { top: Math.round(top), left: Math.round(left) };
};
